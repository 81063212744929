const de = {
  globals: {
    words: {
      total: 'Total',
      standard: 'Standard',
      detailed: 'Detailliert',
    },
  },
  pages: {
    login: {
      labels: {
        username: 'Benutzername',
        password: 'Passwort',
        totp: 'Zifferncode',
        autologin: 'Eingeloggt bleiben?',
        submit: {
          credentials: 'Login',
          totp: 'Bestätigen',
        },
      },
      links: {
        passwordReset: 'Passwort vergessen?',
        imprint: 'Impressum',
        privacy: 'Datenschutz',
      },
      totp: {
        description:
          'Als zusätzliche Sicherheitsmaßnahme geben Sie bitte die von Ihrer Authenticator-App generierte, Ziffernfolge ein.',
      },
      validation: {
        usernameRequired: 'Der Benutzername ist ein Pflichtfeld.',
        passwordRequired: 'Das Passwort ist ein Pflichtfeld.',
        invalidCredentials: 'Benutzername oder Passwort ungültig.',
        totpRequired: 'TOTP ist ein Pflichtfeld.',
        sessionStartFailed: 'Es gab ein Problem bei der Anmeldung. Bitte versuchen Sie es später noch einmal.',
      },
    },
    project: {
      header: {
        projectAddress: 'LV Adresse',
        projectStatus: 'Projekt Status',
        billingType: 'Abrechnung',
        responsibleProjectManager: 'Bauleiter',
        startOfConstruction: 'Baubeginn',
        endOfConstruction: 'Bauende',
        progress: 'Progress',
        //
        totalTime: 'Gesamtzeit',
        alreadyCompleted: 'Bereits erledigt',
        unproductive: 'Unproduktiv',
        unproductiveTime: 'Unproduktive Zeiten',
        productiveTime: 'Produktive Zeiten',
        nonContractual: 'NVA',
        nonContractualTime: 'Nicht vertragliche Arbeiten',
        contractualTimeOriginalPlan: 'Kalkulierte Auftragszeit (Ursprungsplan)',
        addendum: 'Nachtrag',
        deletedPositions: 'Gelöschte Positionen',
        addedUnits: 'Mehrmenge',
        reducedUnits: 'Mindermenge',
        differenceToOriginalCalculation: 'Differenz zur Urkalkulation (Aktueller Plan)',
        currentOrderVolume: 'Aktuelles Auftragsvolumen',
        PAC: 'PIV',
        toBeDone: 'Noch zu erledigen',
        differenceToActualPlan: 'Differenz zum aktuellen Plan',
        differenceToOriginalPlan: 'Differenz zur Ursprungsplan',
        open: 'Offen',
        //
        details: 'Details',
        PACTotal: 'PIV Gesamt',
        last7Days: 'Letzten 7 Tage',
        process: 'Verlauf',
        //
        valuable: 'Abrechenbar',
        nonBillable: 'Nicht Abrechenbar',
        nonValuable: 'Nicht Bewertet',
        //
        travelTime: 'Wegezeiten',
        setupTime: 'Rüstzeiten',
        otherUnproductiveTime: 'Weitere Unpro. Lohnart',
        //
        totalOverview: 'Gesamtübersicht',
        times: 'Zeiten',
        hours: 'Stunden',
        factor: 'Faktor',
        percentage: 'Anteil in %',
        originalOrderTime: 'Ursprüngliche Auftragszeit',
        //
        h: 'Std.',
        //
        PACDetails: 'PIV Details',
      },
    },
  },
};

export default de;
