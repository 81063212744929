const en = {
  globals: {
    words: {
      total: 'Total',
      standard: 'Standard',
      detailed: 'Detailed',
    },
  },
  pages: {
    login: {
      labels: {
        username: 'Username',
        password: 'Password',
        totp: 'One-time code',
        autologin: 'Stay logged-in?',
        submit: {
          credentials: 'Login',
          totp: 'Confirm',
        },
      },
      links: {
        passwordReset: 'Password forgotten?',
        imprint: 'Imprint',
        privacy: 'Privacy',
      },
      totp: {
        description:
          'As an additional security measure, please enter the numeric code generated by your authenticator app.',
      },
      validation: {
        usernameRequired: 'Username is required.',
        passwordRequired: 'Password is required.',
        invalidCredentials: 'Username or password is wrong.',
        totpRequired: 'TOTP is required.',
        sessionStartFailed: 'There was an issue logging in. Please try again later.',
      },
    },
    project: {
      header: {
        projectAddress: 'Project Address',
        projectStatus: 'Project Status',
        billingType: 'Billing Type',
        responsibleProjectManager: 'Responsible Project Manager',
        startOfConstruction: 'Start of Construction',
        endOfConstruction: 'End of Construction',
        progress: 'Progress',
        //
        totalTime: 'Total Time',
        alreadyCompleted: 'Already Completed',
        unproductive: 'Unproductive',
        unproductiveTime: 'Unproductive Time',
        productiveTime: 'Productive Time',
        nonContractual: 'Non Contractual',
        nonContractualTime: 'Non Contractual Time',
        contractualTimeOriginalPlan: 'Contractual Time(Original Plan)',
        addendum: 'Addendum',
        deletedPositions: 'Deleted Positions',
        addedUnits: 'Added Units',
        reducedUnits: 'Reduced Units',
        differenceToOriginalCalculation: 'Difference to Original Calculation (Actual Plan)',
        currentOrderVolume: 'Current Order Volume',
        PAC: 'PAC',
        toBeDone: 'To be Done',
        differenceToActualPlan: 'Difference to Actual Plan',
        differenceToOriginalPlan: 'Difference to Original Plan',
        open: 'Open',
        //
        details: 'Details',
        PACTotal: 'PAC Total',
        last7Days: 'Last 7 Days',
        process: 'Process',
        //
        valuable: 'Valuable',
        nonBillable: 'Non Billable',
        nonValuable: 'Non Valuable',
        //
        travelTime: 'Travel Time',
        setupTime: 'Setup Time',
        otherUnproductiveTime: 'Other Unproductive Time',
        //
        totalOverview: 'Total Overview',
        times: 'Times',
        hours: 'Hours',
        factor: 'Factor',
        percentage: 'Proportion in %',
        originalOrderTime: 'Original order time',
        //
        h: 'h',
        //
        PACDetails: 'PAC Details',
      },
    },
  },
};

export default en;
